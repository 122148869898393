<template>

    <div class="contrainer-right">
      <custom-actions>
        <template #header>
          <!-- <el-button v-if="permission.add" type="primary" icon="el-icon-plus" @click="onAdd"
            >新增</el-button
          > -->
          <el-button v-if="permission.edit" type="primary" icon="el-icon-edit" @click="onAllEdit"
            >批量修改</el-button
          >
        </template>
        <el-form ref="searchForm" :model="searchForm" inline label-width="100px">
          <el-form-item label="设备名称" prop="deviceName">
            <el-input
              v-model="searchForm.deviceName"
              placeholder="请输入设备名称"
            />
          </el-form-item>
          <el-form-item label="设备串号" prop="deviceNum">
            <el-input
              v-model="searchForm.deviceNum"
              placeholder="请输入设备串号"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="onQuery"
              >查询</el-button
            >
            <el-button @click="onReset" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-form>
      </custom-actions>
      <el-card
        class="my-table"
        :body-style="{ padding: '15px' }"
        shadow="never"
      >
        <el-table
          stripe
          size="mini"
          border
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          @expand-change="expandChange"
          @selection-change="slelectTable"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- <el-table-column type="expand">
            <template v-slot="{ row }">
              <div class="title-form">基站内外电流差值：{{Math.abs((row.deviceNew.deviceAmpere || 0) - (row.deviceNew.insideDeviceAmpere || 0))}}</div>
              <div class="title-form">基站外设备信息：</div>
              <el-form
                label-position="right"
                inline
                label-width="140px"
                class="child-form"
              >
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站外设备分合闸">
                      <div>
                        <el-switch
                          v-model="row.deviceNew.showSwitch"
                          :disabled="row.deviceNew.deviceDel == '2'"
                          @change="
                            changeSwitch(
                              row,
                              row.deviceNum,
                              row.deviceNew.deviceState
                            )
                          "
                        ></el-switch>

                        <span class="ml10">{{
                          row.deviceNew.deviceState == "1"?'合闸':'分闸'

                        }}</span>
                      </div>

                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外设备离线状态">
                      <el-tag type="success" v-if='row.deviceNew.deviceDel == "1"'>在线</el-tag>
                      <el-tag type="danger" v-else-if='row.deviceNew.deviceDel == "2"'>离线</el-tag>
                      <span v-else>--</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外设备实时电流">
                      <span>{{ row.deviceNew.deviceAmpere || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外漏电电流">
                      <span>{{ row.deviceNew.ldData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站外A相电流">
                      <span>{{ row.deviceNew.laData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外B相电流">
                      <span>{{ row.deviceNew.lbData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外C相电流">
                      <span>{{ row.deviceNew.lcData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>

                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站外A相电压">
                      <span>{{ row.deviceNew.vaData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外B相电压">
                      <span>{{ row.deviceNew.vbData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外C相电压">
                      <span>{{ row.deviceNew.vcData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站外A相温度">
                      <span>{{ row.deviceNew.waData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外B相温度">
                      <span>{{ row.deviceNew.wbData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站外C相温度">
                      <span>{{ row.deviceNew.wcData || "--" }}</span>
                    </el-form-item>
                  </el-col>

                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>
              </el-form>

              <div class="title-form">基站内设备信息：</div>
              <el-form
                label-position="right"
                inline
                label-width="140px"
                class="child-form"
              >
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站内设备分合闸">
                      <div >
                        <el-switch
                          v-model="row.deviceNew.insideShowSwitch"
                          :disabled="row.deviceNew.insideDeviceDel == '2'"
                          @change="
                            changeInsideSwitch(
                              row,
                              row.deviceNum,
                              row.deviceNew.insideDeviceState
                            )
                          "
                        ></el-switch>
                        <span class="ml10">{{
                           row.deviceNew.insideDeviceState == "1"?'合闸'
                           :'分闸'

                        }}</span>
                      </div>

                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内设备离线状态 ">
                       <el-tag type="success" v-if='row.deviceNew.insideDeviceDel == "1"'>在线</el-tag>
                      <el-tag type="danger" v-else-if='row.deviceNew.insideDeviceDel == "2"'>离线</el-tag>
                      <span v-else>--</span>

                    </el-form-item>
                  </el-col>

                  <el-col :span="4">
                    <el-form-item label="基站内设备实时电流 ">
                      <span>{{
                        row.deviceNew.insideDeviceAmpere || "--"
                      }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内漏电电流 ">
                      <span>{{ row.deviceNew.insideLdData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站内A相电流">
                      <span>{{ row.deviceNew.insideLaData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内B相电流">
                      <span>{{ row.deviceNew.insideLbData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内C相电流">
                      <span>{{ row.deviceNew.insideLcData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站内A相电压">
                      <span>{{ row.deviceNew.insideVaData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内B相电压">
                      <span>{{ row.deviceNew.insideVbData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内C相电压">
                      <span>{{ row.deviceNew.insideVcData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="4">
                    <el-form-item label="基站内A相温度">
                      <span>{{ row.deviceNew.insideWaData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内B相温度">
                      <span>{{ row.deviceNew.insideWbData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item label="基站内C相温度">
                      <span>{{ row.deviceNew.insideWcData || "--" }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="4"></el-col>
                </el-row>
              </el-form>
            </template>
          </el-table-column> -->
          <el-table-column
            label="设备名称"
            prop="deviceName"
            min-width="90"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="所属基站"
            prop="stationName"
            min-width="90"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="设备串号"
            prop="deviceNum"
            min-width="130"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="设备状态"
            prop="deviceNew.deviceDel"
            min-width="70"
            show-overflow-tooltip
          >
          <template v-slot='{row}'>
            <div class="on-line" v-if='row.deviceNew.deviceDel == 1'></div>
            <div class="off-line"  v-if='row.deviceNew.deviceDel == 2'></div>
          </template>
          </el-table-column>
          <el-table-column
            label="瞬时保护"
            prop="instantaneous"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <div :class="row.instantaneous1 == '0' && row.instantaneous2 == '0'?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="短延时"
            prop="shortDelay"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <div :class="row.shortDelay1 == '0' && row.shortDelay2 == '0'?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="过载保护"
            prop="overload"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <div :class=" row.overload1 == '0' &&
                    row.overload2 == '0' &&
                    row.overload3 == '0'?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="漏电保护"
            prop="leakageAction"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.leakageAction1 == '0' && row.leakageAction2 == '0' ?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="过电压保护"
            prop="overvoltage"
            min-width="80"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.overvoltage1 == '0' && row.overvoltage2 == '0' ?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="欠电压保护"
            prop="underVoltage"
            min-width="80"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.underVoltage1 == '0' && row.underVoltage2 == '0' ?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="超温保护"
            prop="overTemperature"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.overTemperature1 == '0' && row.overTemperature2 == '0' ?'close-style':'open-style'"></div>
            </template>
          </el-table-column>
          <el-table-column
            label="拉弧保护"
            prop="arc"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.arc1 == '0' && row.arc2 == '0' && row.arc3 == '0'?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="缺相保护"
            prop="openPhase"
            min-width="70"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.openPhase1 == '0' && row.openPhase2 == '0' ?'close-style':'open-style'"></div>

            </template>
          </el-table-column>
          <el-table-column
            label="自动分合闸"
            prop="autoPower"
            min-width="80"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
               <div :class="row.autoPower ?'open-style':'close-style'"></div>

            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" min-width="200px">
            <template v-slot="{ row }">
              <!-- <el-button v-if="permission.view" type="text" @click="onDetail(row.deviceNum)"
                >查看</el-button
              > -->
              <el-button v-if="permission.edit" type="text" @click="onEdit(row.deviceNum)"
                >编辑</el-button
              >
              <el-button v-if="permission.del" class="del-btn" type="text" @click="onDelete(row.deviceNum)">删除</el-button>
              <el-button type="text" @click="goHistory(row.deviceNum)"
                >历史数据</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page.sync="page"
          :limit.sync="size"
          @pagination="getTableData"
        />
      </el-card>
    </div>

</template>

<script>
export default {
  name: 'DeviceListPage',

  data () {
    return {
      searchForm: {
        deviceName: '',
        stationId: '',
        deviceNum: '',
        parentDeptId: ''
      },
      loading: false,
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      checkedDataList: {},
      expands: []
    }
  },
  computed: {
    permission () {
      return this.$store.state.permission
    }
  },
  created () {

  },
  watch: {
    $route: {
      handler: function (newV) {
        console.log('-----', newV)
        this.searchForm.stationId = newV.params.deptId
        this.searchForm.parentDeptId = newV.params.parentDeptId
        this.getTableData()
      },
      immediate: true
    }

  },
  methods: {
    // 多选
    slelectTable (info) {
      console.log(info)
      this.checkedDataList[this.page] = info
      console.log('this.checkedDataList', this.checkedDataList)
    },

    changeSwitch (row, deviceNum, type) {
      console.log(deviceNum, type)
      row.deviceNew.showSwitch = !row.deviceNew.showSwitch

      this.changeDeviceState(deviceNum, type)
    },
    changeInsideSwitch (row, deviceNum, type) {
      row.deviceNew.insideShowSwitch = !row.deviceNew.insideShowSwitch

      console.log(deviceNum, type)
      this.changeDeviceState(deviceNum, type)
    },
    changeDeviceState (deviceNum, type) {
      console.log('type', type)
      const text = type === '0' ? '合闸' : type === '1' ? '分闸' : ''
      this.$confirm('确定' + text + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        if (type === '0') {
          console.log('合闸')
          // 合闸

          this.$axios
            .post(this.$apis.smartDevice.openDevice, { deviceNum })
            .then(res => {
              this.page = 1
              setTimeout(() => {
                this.loading = false
                this.$message.success('设备设置合闸成功')

                this.getTableData()
              }, 5000)
            })
        } else if (type === '1') {
          console.log('分闸')

          // 分闸
          this.$axios
            .post(this.$apis.smartDevice.closeDevice, { deviceNum })
            .then(res => {
              this.page = 1
              setTimeout(() => {
                this.loading = false
                this.$message.success('设备设置分闸成功')
                this.getTableData()
              }, 5000)
            })
        }
      }).catch(() => {

      })
    },
    expandChange (row) {
      row.deviceNew.showSwitch = row.deviceNew.deviceState === '1'
      row.deviceNew.insideShowSwitch = row.deviceNew.insideDeviceState === '1'

      console.log('showSwitch', row.deviceNew.showSwitch)
      console.log('insideShowSwitch', row.deviceNew.insideShowSwitch)
    },
    getTableData () {
      this.loading = true
      this.checkedDataList = {}
      console.log('search', this.searchForm)
      this.$axios
        .get(this.$apis.smartDevice.selectDeviceByList, {
          params: {
            page: this.page,
            size: this.size,
            ...this.searchForm
          }
        })
        .then(res => {
          if (res) {
            if (res.list && res.list.length > 0) {
              res.list.forEach(item => {
                item.instantaneous1 = item.instantaneous.split('_')[0] || ''
                item.instantaneous2 = item.instantaneous.split('_')[1] || '0'
                item.shortDelay1 = item.shortDelay.split('_')[0] || ''
                item.shortDelay2 = item.shortDelay.split('_')[1] || '0'
                item.leakageAction1 = item.leakageAction.split('_')[0] || ''
                item.leakageAction2 = item.leakageAction.split('_')[1] || '0'
                item.openPhase1 = item.openPhase.split('_')[0] || ''
                item.openPhase2 = item.openPhase.split('_')[1] || '0'

                item.overload1 = item.overload.split('_')[0] || '0'
                item.overload2 = item.overload.split('_')[1] || '0'
                item.overload3 = item.overload.split('_')[2] || '0'
                item.overvoltage1 = item.overvoltage.split('_')[0] || '0'
                item.overvoltage2 = item.overvoltage.split('_')[1] || '0'
                item.underVoltage1 = item.underVoltage.split('_')[0] || '0'
                item.underVoltage2 = item.underVoltage.split('_')[1] || '0'
                item.overTemperature1 = item.overTemperature.split('_')[0] || '0'
                item.overTemperature2 = item.overTemperature.split('_')[1] || '0'
                item.arc1 = item.arc.split('_')[0] || '0'
                item.arc2 = item.arc.split('_')[1] || '0'
                item.arc3 = item.arc.split('_')[2] || '0'
                item.v461 = item.v46.split('_')[0] || '0'
                item.v462 = item.v46.split('_')[1] || '0'
                item.autoPower = item.autoOpeningPower.split('_')[1] === '1'
              })
            }
            this.total = res.total
            this.tableData = res.list

            if (
              this.checkedDataList !== null &&
              JSON.stringify(this.checkedDataList) !== '{}' &&
              this.checkedDataList[this.page] &&
              this.checkedDataList[this.page].length
            ) {
              this.checkedDataList[this.page].forEach(item => {
                this.tableData.forEach(row => {
                  if (row.deviceNum === item.deviceNum) {
                    this.$nextTick(() => {
                      this.$refs.multipleTable.toggleRowSelection(row, true)
                    })
                  }
                })
              })
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onQuery () {
      this.page = 1
      this.getTableData()
    },
    onReset () {
      this.$refs.searchForm.resetFields()
      this.checkedDataList = {}
    },
    goHistory (deviceNum) {
      this.$router.push('/smartDevice/history?deviceNum=' + deviceNum)
    },
    onAdd () {
      this.$router.push({ name: 'deviceAdd' })
    },
    onEdit (id) {
      this.$router.push({
        name: 'smartDeviceEdit',
        params: {
          id
        }
      })
    },
    onAllEdit () {
      const keyList = Object.keys(this.checkedDataList)
      let resultArr = []
      if (keyList.length) {
        for (let i = 0; i < keyList.length; i++) {
          if (this.checkedDataList[keyList[i]].length) {
            resultArr = resultArr.concat(this.checkedDataList[keyList[i]])
          }
        }
      }
      if (!resultArr.length) {
        this.$message({
          message: '您未选中要修改的数据！',
          type: 'warning'
        })

        return
      }
      console.log(resultArr)
      let deviceNumArr = []
      resultArr.forEach(item => {
        deviceNumArr.push(item.deviceNum, item.insideDeviceNum)
      })
      deviceNumArr = Array.from(new Set(deviceNumArr))
      this.$router.push({
        path: '/smartDevice/allEdit',
        query: {
          list: deviceNumArr
        }
      })
    },
    onDetail (id) {
      this.$router.push({
        name: 'smartDeviceDetail',
        params: {
          id
        }
      })
    },
    onDelete (deviceNum) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$apis.smartDevice.deleteDevice, { deviceNum })
            .then(res => {
              this.$message.success('删除成功')
              this.page = 1
              this.getTableData()
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select,
.el-cascader {
  width: 250px;
}
.my-table {
  margin-top: 15px;
}

.org-item {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}

.contrainer {
  display: flex;
  .contrainer-left {
    width: 260px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);

    .card {
      height: 100%;
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}
::v-deep .el-tree {
  font-size: 14px;
  .el-tree-node__content {
    font-size: 14px;
    height: 34px;
    line-height: 34px;
  }
  .el-tree-node__children {
    .el-tree-node .el-tree-node__content {
      font-size: 14px;
      margin: 4px 0;
      height: 34px;
      line-height: 34px;
      padding-right: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 350px;
    }
  }
}
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    width: 100px;
    font-size: 14px;
    color: #999;
    text-align: right;
  }
  .value {
    flex: 1;
    font-size: 14px;
    color: #000;
    margin-left: 10px;
  }
}
.title-form {
  font-size: 14px;
  font-weight: 600;
  margin: 30px 0 20px 0;
  padding-left: 14px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.child-form {
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
.close-style{
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px   rgba(255,0,0,.8);
}
.open-style{
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px   rgba(0,255,0,.8);
}
.on-line{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: green;
}
.off-line{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}
</style>
